import React from 'react';

// import '../Styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
      {/* <div style={{ padding: '20px', maxWidth: '45%', textAlign: "left" }}>
        <h3>Privacy Policy for Rubyscan</h3>
        <p>Last updated: [11-11-2024]</p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to Rubyscan. This Privacy Policy outlines how we collect, use,
          and safeguard your information when you visit our website or use our
          services. By accessing or using our website, you agree to the terms of
          this policy.
        </p>

        <h2>2. Information We Collect</h2>
        <p>
          We may collect personal information that you provide to us directly,
          such as your name, email address, and contact details while using our platform. Additionally, we
          may collect non-personal information such as IP addresses, browser
          types, and usage data to improve our services.
        </p>

        <h2>3. Use of Information</h2>
        <p>
          Rubyscan uses your information to provide and enhance our services,
          respond to inquiries, and personalize your experience. We may also use
          your information for security purposes, compliance, and analytics.
        </p>

        <h2>4. Cookies</h2>
        <p>
          Our website may use cookies to improve your browsing experience and
          analyze website traffic. You may choose to accept or decline cookies in
          your browser settings. Note that disabling cookies may impact the
          functionality of certain website features.
        </p>

        <h2>5. Data Security</h2>
        <p>
          We implement appropriate security measures to protect your personal
          information from unauthorized access, alteration, and disclosure.
          However, no method of transmission over the internet is 100% secure, and
          we cannot guarantee absolute security.
        </p>

        <h2>6. Sharing of Information</h2>
        <p>
          We do not sell or rent your personal information to third parties.
          However, we may share your information with trusted service providers
          who assist us in operating our website, conducting our business, or
          serving our users, as long as these parties agree to keep this
          information confidential.
        </p>

        <h2>7. Third-Party Links</h2>
        <p>
          Rubyscan may contain links to third-party websites. We are not
          responsible for the privacy practices or content of these external sites.
          We encourage you to review the privacy policies of any sites you visit.
        </p>

        <h2>8. Children's Privacy</h2>
        <p>
          Our services are not intended for children under the age of 13. We do
          not knowingly collect personal information from children under 13. If we
          become aware that a child under 13 has provided us with personal
          information, we will delete it immediately.
        </p>

        <h2>9. Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this page periodically for any changes.
        </p>

        <h2>10. Contact Us</h2>
        <p>
          If you have any questions or concerns regarding this Privacy Policy,
          please contact us at{' '}
          <a href="mailto:support@rubyscan.com">support@rubyscan.com</a>.
        </p>
      </div> */}

      <div style={{ padding: '20px', textAlign: "left" }}>
        <h3>Privacy Policy for RubyLink Pro</h3>
        <p>Last updated: [07-11-2024]</p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to RubyLink Pro. This Privacy Policy describes how we collect, use, and protect your data when you use our website or services. By using our platform, you agree to the terms and conditions outlined in this policy.
        </p>

        <h2>2. Information We Collect</h2>
        <p>
          We collect personal information such as your name, email, phone number, and other contact information that you voluntarily provide. Additionally, we may collect non-personal data like IP addresses, device types, and browsing activities to improve our services.
        </p>

        <h2>3. Use of Information</h2>
        <p>
          RubyLink Pro uses your information to provide, maintain, and improve our services. This includes communicating with you, personalizing your experience, and ensuring the security of your account. We may also use your data for marketing and promotional purposes.
        </p>

        <h2>4. Cookies and Tracking Technologies</h2>
        <p>
          Our website uses cookies to enhance your experience. Cookies help us understand how users interact with our site, so we can improve functionality and user engagement. You can manage cookie preferences via your browser settings.
        </p>

        <h2>5. Data Security</h2>
        <p>
          We prioritize the security of your data. We implement industry-standard encryption and security protocols to protect your personal information. However, no security system is entirely foolproof, and we cannot guarantee complete protection.
        </p>

        <h2>6. Sharing of Information</h2>
        <p>
          We do not share your personal information with third parties for their marketing purposes. However, we may share data with trusted service providers who assist us in delivering services, provided they agree to safeguard your data in accordance with this Privacy Policy.
        </p>

        <h2>7. Third-Party Services</h2>
        <p>
          Our platform may contain links to third-party websites. Please be aware that we are not responsible for the privacy practices or content of these external sites. We recommend that you review the privacy policies of these third-party websites.
        </p>

        <h2>8. Children's Privacy</h2>
        <p>
          RubyLink Pro is not intended for children under 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected data from a child under 13, we will take steps to delete it immediately.
        </p>

        <h2>9. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. When we make significant changes, we will notify you by posting an updated version on this page. We encourage you to review this page periodically for any updates.
        </p>

        <h2>10. Contact Us</h2>
        <p>
          If you have any questions or concerns regarding this Privacy Policy, please contact us at{' '}
          <a href="mailto:support@rubylinkpro.com">support@rubylinkpro.com</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
