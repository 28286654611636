import React from 'react';
// import '../Styles/TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div style={{ padding: '20px', display: 'flex', justifyContent: 'space-between', maxWidth: '1200px', margin: 'auto', textAlign: 'left', flexDirection: 'row' }}>
      {/* Left Section for Rubyscan Terms */}
      {/* <div style={{ flex: 1, paddingRight: '20px', marginBottom: '20px' }}>
        <h3>Terms and Conditions for Rubyscan</h3>
        <p>Last updated: [11-11-2024]</p>

        <h4>1. Introduction</h4>
        <p>Welcome to Rubyscan. These terms and conditions outline the rules and regulations for the use of Rubyscan's website and services.</p>
        <p>By accessing this website, you accept these terms and conditions. Do not continue to use Rubyscan if you do not agree to all of the terms and conditions stated on this page.</p>

        <h4>2. Description of Service</h4>
        <p>Rubyscan provides a blockchain explorer service, allowing users to explore, track, and analyze blockchain transactions. Rubyscan is not responsible for the accuracy of information obtained from blockchain data, and users acknowledge that blockchain transactions are immutable and cannot be reversed.</p>

        <h4>3. Intellectual Property Rights</h4>
        <p>Unless otherwise stated, Rubyscan and/or its licensors own the intellectual property rights for all material on Rubyscan. All intellectual property rights are reserved. You may access this from Rubyscan for your own personal use, subject to restrictions set in these terms and conditions.</p>

        <h4>4. User Conduct</h4>
        <p>Users agree not to misuse Rubyscan for illegal or unauthorized purposes. This includes but is not limited to the following activities:</p>
        <ul>
          <li>Accessing data in an unauthorized manner.</li>
          <li>Distributing malicious software or attempting to compromise Rubyscan’s services.</li>
          <li>Impersonating Rubyscan or misrepresenting affiliation with Rubyscan.</li>
        </ul>

        <h4>5. Limitation of Liability</h4>
        <p>Rubyscan and its affiliates, directors, and employees will not be held liable for any damages or losses arising out of or in connection with the use of our website or services. This includes, but is not limited to, any direct, indirect, incidental, punitive, or consequential damages.</p>

        <h4>6. Accuracy of Information</h4>
        <p>Rubyscan makes no warranty of the accuracy, completeness, or reliability of blockchain data displayed on our platform. Users should independently verify information when making decisions based on blockchain data.</p>

        <h4>7. Modifications to Terms</h4>
        <p>Rubyscan reserves the right to modify or replace these terms at any time. It is your responsibility to review these terms periodically for changes. Your continued use of the website following the posting of any changes to the terms signifies acceptance of those changes.</p>

        <h4>8. Contact Us</h4>
        <p>If you have any questions or concerns about these terms and conditions, please contact us at <a href="mailto:support@rubyscan.com">support@rubyscan.com</a>.</p>
      </div> */}

      {/* Right Section for Rubylink Pro Terms */}
      <div style={{ borderLeft: '2px solid #ddd', paddingLeft: '20px', flex: 1, marginBottom: '20px' }}>
        <h3>Terms and Conditions for Rubylink Pro</h3>
        <p>Last updated: [07-11-2024]</p>

        <h4>1. Introduction</h4>
        <p>Welcome to Rubylink Pro. These terms and conditions outline the rules and regulations for the use of Rubylink Pro's website and services.</p>
        <p>By accessing this website, you accept these terms and conditions. Do not continue to use Rubylink Pro if you do not agree to all of the terms and conditions stated on this page.</p>

        <h4>2. Description of Service</h4>
        <p>Rubylink Pro provides a premium blockchain explorer service, enabling users to explore, track, and analyze blockchain transactions at an advanced level. Rubylink Pro offers enhanced features, faster access, and advanced tools for professional blockchain analysis. Rubylink Pro is not responsible for the accuracy of information obtained from blockchain data, and users acknowledge that blockchain transactions are immutable and cannot be reversed.</p>

        <h4>3. Intellectual Property Rights</h4>
        <p>Unless otherwise stated, Rubylink Pro and/or its licensors own the intellectual property rights for all material on Rubylink Pro. All intellectual property rights are reserved. You may access this from Rubylink Pro for your own personal or professional use, subject to restrictions set in these terms and conditions.</p>

        <h4>4. User Conduct</h4>
        <p>Users agree not to misuse Rubylink Pro for illegal or unauthorized purposes. This includes but is not limited to the following activities:</p>
        <ul>
          <li>Accessing data in an unauthorized manner.</li>
          <li>Distributing malicious software or attempting to compromise Rubyscan Pro’s services.</li>
          <li>Impersonating Rubylink Pro or misrepresenting affiliation with Rubylink Pro.</li>
          <li>Using Rubylink Pro for financial gain without proper authorization.</li>
        </ul>

        <h4>5. Limitation of Liability</h4>
        <p>Rubylink Pro and its affiliates, directors, and employees will not be held liable for any damages or losses arising out of or in connection with the use of our website or services. This includes, but is not limited to, any direct, indirect, incidental, punitive, or consequential damages.</p>

        <h4>6. Accuracy of Information</h4>
        <p>Rubylink Pro makes no warranty of the accuracy, completeness, or reliability of blockchain data displayed on our platform. Users should independently verify information when making decisions based on blockchain data, especially for professional or financial purposes.</p>

        <h4>7. Modifications to Terms</h4>
        <p>Rubylink Pro reserves the right to modify or replace these terms at any time. It is your responsibility to review these terms periodically for changes. Your continued use of the website following the posting of any changes to the terms signifies acceptance of those changes.</p>

        <h4>8. Subscription and Payments</h4>
        <p>Rubylink Pro is a subscription-based service. By subscribing, you agree to pay the subscription fees in accordance with the terms outlined during the subscription process. All payments are non-refundable except as otherwise specified in our Refund Policy.</p>

        <h4>9. Contact Us</h4>
        <p>If you have any questions or concerns about these terms and conditions, please contact us at <a href="mailto:support@rubyscanpro.com">support@rubyscanpro.com</a>.</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
